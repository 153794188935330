




















import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import Title from '@/components/common/page-layout/Title.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import ProgressLine from '@/components/ProgressLine.vue'
@Component({
  components: {
    PageLayout,
    Title,
    ContentArea,
    ProgressLine
  }
})

export default class Terms extends Vue {
 private topShadow = false
  private bottomShadow = true
  private oldScroll = 0
  private content = ''
  private created (): void {
    this.$store.dispatch('terms/getTermsData').then((res:string) => {
      this.content = res
    })
  }

  private mounted (): void {
    document.body.classList.add('hide-scroll')
    if (window.innerWidth < 768) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }
  }

  private scrollEvent (ev: { target: HTMLDivElement }): void {
    if (this.oldScroll > ev.target.scrollTop) {
      this.topShadow = false
      this.bottomShadow = true
    } else {
      this.topShadow = true
      this.bottomShadow = false
    }
    this.oldScroll = ev.target.scrollTop
  }

  private destroyed (): void {
    document.body.classList.remove('hide-scroll')
  }

  private goBack (): void {
    this.$router.go(-1)
  }
}
